// app/javascript/controllers/time_slots_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slotTemplate", "slots"];

  connect() {
    console.log("this is connected");
  }

  addSlot(event) {
    event.preventDefault();

    // Clone the slot template
    const newSlot = this.slotTemplateTarget.content.cloneNode(true);

    // Append the new slot to the slots container
    this.slotsTarget.appendChild(newSlot);
  }

  removeSlot(event) {
    event.preventDefault();

    const slot = event.target.closest(".time-slot-fields");

    // If the field is a newly added field (doesn't have a persisted object), just remove the element
    if (slot.querySelector('input[name*="_destroy"]')) {
      slot.querySelector('input[name*="_destroy"]').value = "1";
      slot.style.display = "none";

      const inputs = slot.querySelectorAll("input, select, textarea");
      inputs.forEach((input) => {
        input.disabled = true;
        input.required = false;
      });
    } else {
      slot.remove(); // Remove the newly added slots before submitting
    }
  }
}
